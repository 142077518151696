import * as React from "react";

import "./discography.scss";

import {ReleaseStore} from "../../store/releaseStore";
import {BuyOnAmazonButton} from "../button/buyOnAmazonButton";
import {BuyOnITunesButton} from "../button/buyOnITunesButton";
import {Release} from "../../model/release";

export interface DiscographyProps {
    discographyStore: ReleaseStore
}

/**
 * Widget that displays the band's discography.
 */
export class Discography extends React.Component<DiscographyProps, null> {

    render() {
        let albums = this.props.discographyStore.albums;
        let singles = this.props.discographyStore.singles;

        let singles123 = singles.splice(0, 3);
        let singlesRemaining = singles;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h2>Albums & EPs</h2>
                        <br/>
                        <div className="card-deck">
                            {albums.map((album, index) => {
                                return this.renderRelease(album, index);
                            })}
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col">
                        <h2>Singles</h2>
                        <br/>
                        <div className="card-deck">
                            {singles123.map((single, index) => {
                                return this.renderRelease(single, index);
                            })}
                        </div>
                        <br/>
                        <div className="card-deck">
                            {singlesRemaining.map((single, index) => {
                                return this.renderRelease(single, index);
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private renderRelease(release: Release, index: number) {
        return (
            <div key={"release-" + index} className="card">
                <img className="card-img-top" src={release.coverImage} alt={release.name}/>
                <div className="card-body">
                    <h5 className="card-title">{release.name}</h5>
                    <p className="card-text">
                        <small className="text-muted">{release.type}, {release.year}</small>
                    </p>
                    <p>
                        { (release.linkAmazon) ? <BuyOnAmazonButton link={release.linkAmazon} height={32}/> : null}
                        &nbsp;
                        { (release.linkITunes) ? <BuyOnITunesButton link={release.linkITunes} height={32}/> : null}
                    </p>
                </div>
            </div>
        );
    }

}