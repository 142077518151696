import {Release} from "../model/release";

import coverSchwarzesLicht from "./../static/img/releases/schwarzes_licht_und_weisses_rauschen_500x500.jpg";
import coverNebelmanoever from "./../static/img/releases/nebelmanoever_v2_500x500.jpg";
import coverDieArbeiterVonWien from "./../static/img/releases/die_arbeiter_von_wien_500x500.jpg";
import coverDionysosPass from "./../static/img/releases/dionysos_pass_v2_500x500.jpg";
import coverSukkubus from "./../static/img/releases/sukkubus_v2_500x500.jpg";
import coverLaudanum from "./../static/img/releases/laudanum_ins_ohr_500x500.jpg";
import coverBabylon from "./../static/img/releases/babylon_500x500.jpg";
import coverDasLuftschiff from "./../static/img/releases/das_luftschiff_500x500.jpg";

export const RELEASE_DATA: Release[] = [
    {
        name: "Schwarzes Licht & Weißes Rauschen",
        year: 2017,
        type: "EP",
        description: "Brand-new four-track EP.",
        linkAmazon: "https://www.amazon.de/dp/B077XCMB3H",
        linkITunes: "https://itunes.apple.com/de/album/1321855109",
        coverImage: coverSchwarzesLicht,
        tracks: [{name: "Antiheld"}, {name: "Gargoyle"}, {name: "Maskenball"}, {name: "Epilog"}]
    },
    {
        name: "Babylon",
        year: 2022,
        type: "Single",
        description: "",
        linkAmazon: "https://www.amazon.de/dp/B0B27MJTSG",
        linkITunes: "https://music.apple.com/us/album/babylon/1625941366?i=1625941512",
        coverImage: coverBabylon,
        tracks: [{name: "Babylon"}]
    },
    {
        name: "Die Arbeiter von Wien",
        year: 2022,
        type: "Single",
        description: "",
        linkAmazon: "https://www.amazon.de/dp/B09TDTQSH3",
        linkITunes: "https://music.apple.com/us/album/die-arbeiter-von-wien-single/1611391486",
        coverImage: coverDieArbeiterVonWien,
        tracks: [{name: "Die Arbeiter von Wien"}]
    },
    {
        name: "Das Luftschiff",
        year: 2021,
        type: "Single",
        description: "",
        linkAmazon: "https://amazon.de/music/player/albums/B08TB6BHSG",
        linkITunes: "https://music.apple.com/us/album/das-luftschiff/1549573793?i=1549574012",
        coverImage: coverDasLuftschiff,
        tracks: [{name: "Das Luftschiff"}]
    },
    {
        name: "Nebelmanöver",
        year: 2016,
        type: "Single",
        description: "Beitrag zum Sampler \"Feat. Respect\"",
        linkAmazon: "https://www.amazon.de/dp/B07H9CMYJ4/",
        linkITunes: "https://itunes.apple.com/de/album/nebelman%C3%B6ver-single/1436153128",
        coverImage: coverNebelmanoever,
        tracks: [{name: "Nebelmanöver"}]
    },
    {
        name: "Dionysos Pass",
        year: 2016,
        type: "Single",
        description: "",
        linkAmazon: "https://www.amazon.de/dp/B01MXKC6M9",
        linkITunes: "https://itunes.apple.com/de/album/dionysos-pass-single/id1176056402",
        coverImage: coverDionysosPass,
        tracks: [{name: "Dionysos Pass"}]
    },
    {
        name: "Sukkubus",
        year: 2016,
        type: "Single",
        description: "",
        linkAmazon: "https://www.amazon.de/dp/B01N8WR0L6",
        linkITunes: "https://itunes.apple.com/de/album/sukkubus-single/id1167012278",
        coverImage: coverSukkubus,
        tracks: [{name: "Sukkubus"}]
    },
    {
        name: "Laudanum ins Ohr",
        year: 2016,
        type: "Album",
        description: "Das Debüt-Album von Turm & Strang.",
        linkAmazon: "https://www.amazon.de/gp/product/B019RJ1J7W",
        linkITunes: "https://itunes.apple.com/de/album/laudanum-ins-ohr/id1070690007",
        coverImage: coverLaudanum,
        tracks: [
            {name: "Wendepunkt"}, {name: "Leitwolf"}, {name: "Victoria"}, {name: "Aschewolke"}, {name: "Tiefenrausch"},
            {name: "Schreigebet"}, {name: "Protagonistin"}, {name: "Heizarmee"}, {name: "Vollbrand"}, {name: "Shiva"}
        ]
    }
];