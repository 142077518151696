import * as React from "react";

import "./socialButtonBar.scss";
import {FacebookIcon, InstagramIcon, SpotifyIcon, TwitterIcon, YouTubeIcon} from "../icon";

/**
 * Widget that renders a button-bar with social-network buttons.
 */
export class SocialButtonBar extends React.Component {

    render() {
        return (
            <nav className="social-button-bar">
                <ul>
                    <li><a href="https://www.facebook.com/turmundstrang" target="_blank">Facebook <FacebookIcon/></a></li>
                    <li><a href="https://www.youtube.com/turmundstrangband" target="_blank">YouTube <YouTubeIcon/></a></li>
                    <li><a href="https://www.instagram.com/turmundstrang" target="_blank">Instagram <InstagramIcon/></a></li>
                    <li><a href="https://open.spotify.com/artist/7fknYGQCA6QZDgaAtW8oSq?si=t6I0n1b8SXSbg5kEwWdAdQ" target="_blank">Spotify <SpotifyIcon/></a></li>
                    <li><a href="https://twitter.com/turmundstrang" target="_blank">Twitter <TwitterIcon/></a></li>
                </ul>
            </nav>
        );
    };
}