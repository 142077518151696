import * as React from "react";

import "./concertCalendar.scss";

import {ConcertStore} from "../../store/concertStore";
import {Concert} from "../../model/concert";
import {formatDate, isInPast} from "../../common/dateUtil";

export interface ConcertCalendarProps {
    concertStore: ConcertStore
}

/**
 * Widget that displays the band's concert-dates.
 */
export class ConcertCalendar extends React.Component<ConcertCalendarProps, null> {

    render() {
        let concerts: Concert[] = this.props.concertStore.data;

        let pastConcerts = concerts.filter(concert => isInPast(concert.date));
        let upcomingConcerts = concerts.filter(concert => !isInPast(concert.date));

        return (
            <div className="concert-calendar">
                <table className="concert-calendar-table table">
                    <thead>
                    <tr>
                        <th scope="col" colSpan={2}>WANN</th>
                        <th scope="col">WO</th>
                        <th scope="col">INFO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {upcomingConcerts.map((concert, index) => {
                        return (
                            <tr key={"concert-" + index} className="upcomingConcertRow">
                                <td>{formatDate(concert.date)}</td>
                                <td>{concert.time}</td>
                                <td>{concert.location}</td>
                                <td>
                                    {
                                        concert.link ? <a href={concert.link} className="concert-link">Link</a> : null
                                    }
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td colSpan={4}/>
                    </tr>
                    {pastConcerts.map((concert, index) => {
                        return (
                            <tr key={"concert" + "-" + index} className="pastConcertRow">
                                <td>{formatDate(concert.date)}</td>
                                <td>{concert.time}</td>
                                <td>{concert.location}</td>
                                <td>
                                    {
                                        concert.link ? <a href={concert.link} className="concert-link">Link</a> : null
                                    }
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>

            </div>
        );
    };
}