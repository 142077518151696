import * as React from "react";

import "./headline.scss";

export class HeadlineProps {
    text: string;
}

export class Headline extends React.Component<HeadlineProps, null> {

    render() {
        return (
            <div className="headline-container">
                <h1 className="headline">{this.props.text}</h1>
            </div>
        );
    }
}