import * as React from "react";

import "./footer.scss";

export class Footer extends React.Component<{}, {}> {

    render() {
        return (
            <footer id="footer">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="offset-1 col-10">
                            <p>&copy; 2015-2024 Turm &amp; Strang</p>
                            <p>developed by <a href="https://www.facebook.com/profile.php?id=100009353144811">dr.sobo</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

}