import * as React from "react";

import "./contactInfo.scss";
import {LinkIcon} from "../icon";

export class ContactInfo extends React.Component {

    render() {
        return (
            <div id="contact-info">
                <div className="row">
                    <div className="col">
                        <div className="table-responsive">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <td colSpan={2}><h2>Contact</h2></td>
                                </tr>
                                <tr>
                                    <td className="contact-info-type">General</td>
                                    <td>{/*<MailIcon/>&nbsp; */}<a href="mailto:mail@canisrecords.com">mail@canisrecords.com</a></td>
                                </tr>
                                <tr>
                                    <td className="contact-info-type">Booking</td>
                                    <td>{/*<MailIcon/>&nbsp; */}<a href="mailto:booking@canisrecords.com">booking@canisrecords.com</a></td>
                                </tr>
                                <tr>
                                    <td className="contact-info-type">Press</td>
                                    <td>{/*<MailIcon/>&nbsp; */}<a href="mailto:presse@canisrecords.com">presse@canisrecords.com</a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col">
                        <div className="table-responsive">
                            <table className="table imprint">
                                <tbody>
                                <tr>
                                    <td colSpan={2}><h2>Imprint</h2></td>
                                </tr>
                                <tr>
                                    <td>
                                        Canis Records<br/>
                                        Sautergasse 27-29/35<br/>
                                        1160 Vienna, Austria<br/>
                                        <LinkIcon/> <a href="https://www.canisrecords.com">Canis Records</a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}