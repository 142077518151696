import * as React from "react";
import amazonPng from "./../../static/img/store/amazon.png";

export interface BuyOnAmazonButtonProps {
    link: string,
    width?: number,
    height?: number
}

export class BuyOnAmazonButton extends React.Component<BuyOnAmazonButtonProps, null> {
    render() {
        return (
            <a href={this.props.link} target="_blank">
                <img src={amazonPng}
                     width={this.props.width}
                     height={this.props.height}/>
            </a>
        );
    }
}