import {action, computed, observable} from "mobx";
import {Release} from "../model/release";

export class ReleaseStore {

    @observable
    data: Release[] = [];

    @action
    public add(entity: Release): Release {
        this.data.push(entity);
        return entity;
    }

    @computed
    public get albums(): Release[] {
        return this.data.filter(r => (r.type === "EP" || r.type === "Album"));
    }

    @computed
    public get singles(): Release[] {
        return this.data.filter(r => (r.type === "Single"));
    }

}