import * as React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {MainView} from "./main/mainView";
import {ConcertStore} from "../store/concertStore";
import {ReleaseStore} from "../store/releaseStore";
import {CONCERT_DATA} from "../data/concertData";
import {RELEASE_DATA} from "../data/releaseData";

// initialize stores
let concertStore = new ConcertStore();
let releaseStore = new ReleaseStore();

// add data
CONCERT_DATA.forEach(concert => concertStore.add(concert));
RELEASE_DATA.forEach(release => releaseStore.add(release));

export const Root = () => (
    <Router>
        <div>
            {/*<DevTools/>*/}
            <Route path="/" component={() => {
                return <MainView concertStore={concertStore} discographyStore={releaseStore}/>
            }}/>
        </div>
    </Router>
);