import * as React from "react";
import * as FontAwesome from "react-fontawesome";

export class HomeIcon extends React.Component {
    render() {
        return <FontAwesome name="home"/>;
    }
}

export class LinkIcon extends React.Component {
    render() {
        return <FontAwesome name="external-link"/>;
    }
}

export class MailIcon extends React.Component {
    render() {
        return <FontAwesome name="envelope"/>;
    }
}

export class PhoneIcon extends React.Component {
    render() {
        return <FontAwesome name="phone"/>;
    }
}

export class UserIcon extends React.Component {
    render() {
        return <FontAwesome name="user"/>;
    }
}

export class ShoppingCartIcon extends React.Component {
    render() {
        return <FontAwesome name="shopping-cart"/>;
    }
}

// Social networks

export class FacebookIcon extends React.Component {
    render() {
        return <FontAwesome name="facebook" className="social-button"/>;
    }
}

export class InstagramIcon extends React.Component {
    render() {
        return <FontAwesome name="instagram" className="social-button"/>;
    }
}

export class SoundcloudIcon extends React.Component {
    render() {
        return <FontAwesome name="soundcloud" className="social-button"/>;
    }
}

export class SpotifyIcon extends React.Component {
    render() {
        return <FontAwesome name="spotify" className="social-button"/>;
    }
}

export class TwitterIcon extends React.Component {
    render() {
        return <FontAwesome name="twitter" className="social-button"/>;
    }
}

export class YouTubeIcon extends React.Component {
    render() {
        return <FontAwesome name="youtube" className="social-button"/>;
    }
}
