import {action, observable} from "mobx";
import {Concert} from "../model/concert";

export class ConcertStore {

    @observable
    data: Concert[] = [];

    @action
    public add(entity: Concert): Concert {
        this.data.push(entity);
        return entity;
    }

}