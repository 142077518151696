export function formatDate(date: Date): string {
    let monthNames = [
        "Jan.", "Feb", "März", "Apr.", "Mai", "Juni", "Juli", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."
    ];

    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();

    return day + ". " + monthNames[monthIndex] + " " + year;
}

export function isInPast(date: Date): boolean {
    let today: Date = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    return (date.getTime() < today.getTime());
}