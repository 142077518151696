import * as React from "react";

import "./navLink.scss";

interface NavLinkProps {
    name: string;
    onClick: () => void;
    active: boolean;
}

export class NavLink extends React.Component<NavLinkProps, null> {

    render() {
        let classNames: string = "nav-item nav-link" + (this.props.active ? " active" : "");

        return (
            <a className={classNames} onClick={this.props.onClick}>{this.props.name}</a>
        );
    }
}