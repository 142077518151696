import {Concert} from "../model/concert";
import {Month} from "../common/month";

export const CONCERT_DATA: Concert[] = [
    {
        date: new Date(2024, Month.SEP, 29),
        location: "Anno 1900 Steampunk Convention (Luxemburg)",
        link: "https://minettpark.lu/de/events/anno-1900-steampunk-convention-3/"
    },
    {
        date: new Date(2024, Month.SEP, 28),
        location: "Anno 1900 Steampunk Convention (Luxemburg)",
        link: "https://minettpark.lu/de/events/anno-1900-steampunk-convention-3/"
    },
    {
        date: new Date(2024, Month.MAY, 5),
        location: "Märkisches Dampfspektakel (GER)",
        link: "https://www.ziegeleipark.de/zp_veranstaltungen/maerkisches-dampfspektakel-2024"
    },
    {
        date: new Date(2024, Month.MAY, 4),
        location: "Märkisches Dampfspektakel (GER)",
        link: "https://www.ziegeleipark.de/zp_veranstaltungen/maerkisches-dampfspektakel-2024"
    },
    {
        date: new Date(2023, Month.SEP, 24),
        location: "Anno 1900 Steampunk Convention (Luxemburg)",
        link: "https://anno1900.lu/"
    },
    {
        date: new Date(2023, Month.SEP, 23),
        location: "Anno 1900 Steampunk Convention (Luxemburg)",
        link: "https://anno1900.lu/"
    },
    {
        date: new Date(2022, Month.SEP, 17),
        location: "Feat. Respect / Sea Shepherd Show @ The Loft, Wien",
        link: "https://www.theloft.at/"
    },
    {
        date: new Date(2022, Month.SEP, 4),
        location: "Steamrose Zeitreise Festival Forst/Lausitz (GER)",
        link: "http://www.steamrose.de"
    },
    {
        date: new Date(2019, Month.SEP, 6),
        time: "18:00 - 21:00",
        location: "Herbstvolksfest Nürnberg (GER)"
    },
    {
        date: new Date(2019, Month.JUL, 7),
        time: "13:30",
        location: "Kohle, Dampf & Eisenglanz (GER)",
        link: "https://www.facebook.com/events/956174728106214/"
    },
    {
        date: new Date(2019, Month.JUL, 6),
        time: "20:30",
        location: "Kohle, Dampf & Eisenglanz (GER)",
        link: "https://www.facebook.com/events/956174728106214/"
    },
    {
        date: new Date(2018, Month.DEC, 1),
        time: "19:00",
        location: "Teufellauf Hollabrunn (NÖ)",
        link: "https://www.facebook.com/events/473494189811133/"
    },
    {
        date: new Date(2018, Month.NOV, 26),
        location: "Replugged Wien"
    },
    {
        date: new Date(2018, Month.SEP, 21),
        location: "Ann and Pet Linz (mit Anstaltskinda)"
    },
    // {
    //     date: new Date(2018, Month.SEP, 1),
    //     location: "Gothic Weekend, Klagenfurt",
    //     link: "https://www.facebook.com/events/476263642832244/"
    // },
    {
        date: new Date(2018, Month.JUN, 1),
        time: "19:30",
        location: "Aera Wien (mit Anstaltskinda)"
    },
    {
        date: new Date(2018, Month.FEB, 9),
        location: "Replugged Wien",
        link: "https://www.facebook.com/events/787603358089481/"
    },
    {
        date: new Date(2017, Month.DEC, 8),
        location: "EP Release, Fledermaus Wien"
    },
    {
        date: new Date(2017, Month.NOV, 10),
        location: "Local Wien, mit River Tyne",
        link: "https://www.facebook.com/events/1025718024236410/"
    },
    {
        date: new Date(2017, Month.OCT, 29),
        location: "JULES 3, Leipziger Fantasymarkt (GER)",
        link: "https://www.facebook.com/events/1711391782503793/"
    },
    {
        date: new Date(2017, Month.OCT, 28),
        location: "JULES 3, Leipziger Fantasymarkt (GER)",
        link: "https://www.facebook.com/events/1711391782503793/"
    },
    {
        date: new Date(2017, Month.JUN, 10),
        location: "Steamfest Budapest (HUN)"
    },
//  {
//      date: new Date(2017, MAY, 26),
//      location: "Jubiläumskonzert, Aera Wien"
//  },
    {
        date: new Date(2017, Month.APR, 30),
        location: "Veitstanz, Weberknecht Wien"
    },
    {
        date: new Date(2017, Month.MAR, 4),
        location: "Aera Wien (geschlossene Gesellschaft)",
        link: "https://www.facebook.com/events/835432126598037/"
    },
    {
        date: new Date(2016, Month.DEC, 5),
        location: "Fledermaus (Wien)"
    },
    {
        date: new Date(2016, Month.NOV, 26),
        location: "Teufellauf Hollabrunn (NÖ)"
    },
    {
        date: new Date(2016, Month.SEP, 24),
        location: "Slow One Club, Brünn (CZ)",
        link: "http://www.slowone.cz/en/"
    },
    {
        date: new Date(2016, Month.AUG, 13),
        time: "19:30",
        location: "Aera Wien, mit Lolita KompleX & Exit To Eden",
        link: "https://www.facebook.com/events/1740328626248135/"
    },
    {
        date: new Date(2016, Month.JUN, 17),
        location: "Jubiläumskonzert, Aera Wien",
        link: "http://www.aera.at/"
    },
    {
        date: new Date(2016, Month.MAY, 28),
        time: "00:00",
        location: "Schwarzer Reigen, Schloss Neugebäude (Wien)",
        link: "https://www.facebook.com/events/1143485242353135/"
    },
    {
        date: new Date(2016, Month.MAY, 25),
        location: "Sommerfest Hagenberg (OÖ)"
    },
    {
        date: new Date(2016, Month.APR, 2),
        time: "18:00",
        location: "Szene Wien",
        link: "http://www.rocktheisland.at/band/198-turm-amp-strang/"
    },
    {
        date: new Date(2016, Month.FEB, 20),
        time: "20:00",
        location: "Steampunk Revue im Weberknecht, Wien",
        link: "https://www.facebook.com/events/780114568778341/"
    },
    {
        date: new Date(2015, Month.NOV, 27),
        time: "21:00",
        location: "Cafe Carina, Wien",
        link: "http://www.cafe-carina.at/live/?m=20151127&cat=3"
    },
    {
        date: new Date(2015, Month.JUN, 26),
        time: "19:00",
        location: "Aera Wien",
        link: "http://www.aera.at/events/details/turm-strang/"
    }
];