import * as React from "react";
import * as scrollToComponent from "react-scroll-to-component";

import {ConcertStore} from "../../store/concertStore";
import {ConcertCalendar} from "../concert/concertCalendar";
import {ContactForm} from "../contact/contactForm";
import {ContactInfo} from "../contact/contactInfo";
import {Discography} from "../discography/discography";
import {Footer} from "./footer";
import {Logo} from "../logo";
import {NavLink} from "./navLink";
import {ReleaseStore} from "../../store/releaseStore";
import {SocialButtonBar} from "../social/socialButtonBar";

import Waypoint from "react-waypoint";

import storeTShirtsPng from "./../../static/img/store/store_tshirts.png";
import storeItemsPng from "./../../static/img/store/store_items.png";

import 'jquery/dist/jquery.min';
import 'popper.js/dist/popper.min';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min';

import "./mainView.scss";
// TODO - move to own component
import {Carousel} from "react-responsive-carousel";
import "../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import {Headline} from "../headline/headline";
import {FacebookIcon, InstagramIcon, ShoppingCartIcon, SpotifyIcon, YouTubeIcon} from "../icon";

export interface MainViewProps {
    concertStore: ConcertStore,
    discographyStore: ReleaseStore
}

export interface MainViewState {
    activeNavLinkId: number;
}

export class MainView extends React.Component<MainViewProps, MainViewState> {

    private sectionMain;
    private sectionTour;
    private sectionMusic;
    private sectionVideo;
    private sectionShop;
    private sectionContact;

    constructor(props) {
        super(props);
        this.state = {activeNavLinkId: 1};
    }

    render() {
        return (
            <div>
                <SocialButtonBar/>

                <div className="container">
                    <nav className="navbar navbar-dark fixed-top navbar-expand-lg">

                        <Logo width={50} height={50}/>

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div id="navigation" className="collapse navbar-collapse">
                            <div className="navbar-nav">
                                <NavLink name="Home" active={this.isActive(1)} onClick={() => this.onNavLinkClick(this.sectionMain)}/>
                                <NavLink name="Tour" active={this.isActive(2)} onClick={() => this.onNavLinkClick(this.sectionTour)}/>
                                <NavLink name="Music" active={this.isActive(3)} onClick={() => this.onNavLinkClick(this.sectionMusic)}/>
                                <NavLink name="Videos" active={this.isActive(4)} onClick={() => this.onNavLinkClick(this.sectionVideo)}/>
                                <NavLink name="Shop" active={this.isActive(5)} onClick={() => this.onNavLinkClick(this.sectionShop)}/>
                                <NavLink name="Contact" active={this.isActive(6)} onClick={() => this.onNavLinkClick(this.sectionContact)}/>
                            </div>
                        </div>
                    </nav>
                </div>


                <section id="section-main" ref={(section) => this.sectionMain = section}>
                    <div className="container-fluid h-100">
                        <Waypoint onEnter={() => this.onScroll(1)} topOffset={-200}/>

                        <div className="row align-items-center h-100">
                            <div className="offset-lg-4 col-lg-4 offset-md-3 col-md-6 offset-sm-2 col-sm-8 offset-1 col-10">
                                <div className="main-logo">
                                    <div className="main-logo-header">Turm &amp; Strang</div>
                                    <div className="main-logo-tagline">Austria's first Steampunk-Band</div>
                                    <div className="main-logo-social-buttons">
                                        <a href="https://www.facebook.com/turmundstrang" target="_blank"><FacebookIcon/></a>
                                        <a href="https://www.youtube.com/turmundstrangband" target="_blank"><YouTubeIcon/></a>
                                        <a href="https://www.instagram.com/turmundstrang/" target="_blank"><InstagramIcon/></a>
                                        <a href="https://open.spotify.com/artist/7fknYGQCA6QZDgaAtW8oSq?si=t6I0n1b8SXSbg5kEwWdAdQ" target="_blank"><SpotifyIcon/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="section-tour" ref={(section) => this.sectionTour = section}>
                    <div className="container-fluid">
                        <Headline text="Tour"/>
                        <br/>
                        <Waypoint onEnter={() => this.onScroll(2)}/>

                        <div className="row">
                            <div className="offset-lg-2 col-lg-8 offset-md-2 col-md-8 offset-sm-1 col-sm-10 col-12">
                                <ConcertCalendar concertStore={this.props.concertStore}/>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="section-music" ref={(section) => this.sectionMusic = section}>
                    <div className="container-fluid">
                        <Headline text="Music"/>
                        <br/>
                        <Waypoint onEnter={() => this.onScroll(3)}/>

                        <div className="row">
                            <div className="offset-lg-2 col-lg-8 offset-md-2 col-md-8 offset-sm-1 col-sm-10 col-12">
                                <Discography discographyStore={this.props.discographyStore}/>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="section-video" ref={(section) => this.sectionVideo = section}>
                    <div className="container-fluid h-100">
                        <Headline text="Videos"/>
                        <br/>
                        <Waypoint onEnter={() => this.onScroll(4)}/>

                        <div className="row">
                            <div className="offset-lg-2 col-lg-8 offset-md-2 col-md-8 offset-sm-1 col-sm-10 col-12">

                                <div className="video-content">
                                    <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} emulateTouch={true}>
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe scrolling="no" allowFullScreen src="https://www.youtube.com/embed/bCvDB4USmqo"/>
                                        </div>
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe scrolling="no" allowFullScreen src="https://www.youtube.com/embed/m_zqEY8vDs0"/>
                                        </div>
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe scrolling="no" allowFullScreen src="https://www.youtube.com/embed/Y8A4hggcGBo"/>
                                        </div>
                                    </Carousel>
                                </div>

                                <div className="video-content-lg">
                                    <div className="embed-responsive embed-responsive-16by9 video">
                                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/bCvDB4USmqo" allowFullScreen/>
                                    </div>
                                    <div className="embed-responsive embed-responsive-16by9 video">
                                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/m_zqEY8vDs0" allowFullScreen/>
                                    </div>
                                    <div className="embed-responsive embed-responsive-16by9 video">
                                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/Y8A4hggcGBo" allowFullScreen/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                <section id="section-shop" ref={(section) => this.sectionShop = section}>
                    <div className="container-fluid h-100">
                        <Headline text="Shop"/>
                        <br/>
                        <Waypoint onEnter={() => this.onScroll(5)}/>

                        <div className="row">
                            <div className="offset-lg-2 col-lg-8 offset-md-2 col-md-8 offset-sm-1 col-sm-10 col-12">
                                <div className="shop">
                                    <div className="shop-images">
                                        <a href="https://radiant-merch.store/pages/turmundstrang" target="_blank">
                                            <img className="shirts" src={storeTShirtsPng} alt="Visit the official Turm & Strang shop!"/>
                                            <img className="items" src={storeItemsPng} alt="Visit the official Turm & Strang shop!"/>
                                        </a>
                                    </div>
                                    <div className="shop-description">
                                        <div className="shop-description-header">Official T&S Merch</div>
                                        <div className="shop-description-tagline">
                                            available online via
                                        </div>
                                        <div className="shop-description-radiant">
                                            <a href="https://radiant-merch.store/pages/turmundstrang" target="_blank">RADIANT Merchandise</a>
                                        </div>
                                        <div className="shop-description-link">
                                            <a href="https://radiant-merch.store/pages/turmundstrang" target="_blank">
                                                <ShoppingCartIcon/> VISIT OUR SHOP
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="section-contact" ref={(section) => this.sectionContact = section}>
                    <div className="container-fluid">
                        <Headline text="Contact"/>
                        <br/>
                        <Waypoint onEnter={() => this.onScroll(6)}/>

                        <div className="row align-items-center">
                            <div className="offset-lg-2 col-lg-8 offset-md-2 col-md-8 offset-sm-1 col-sm-10 col-12">
                                <ContactInfo/>
                            </div>
                        </div>
                        {/*<div className="row align-items-center">*/}
                        {/*    <div className="offset-lg-2 col-lg-8 offset-md-2 col-md-8 offset-sm-1 col-sm-10 col-12">*/}
                        {/*        <ContactForm/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </section>

                <Footer/>
            </div>
        );
    };

    isActive(navLinkId: number) {
        return (this.state.activeNavLinkId === navLinkId);
    }

    onNavLinkClick(scrollToRef: any) {
        scrollToComponent(scrollToRef, {
            offset: 0,
            align: "top",
            ease: "",
            duration: 1000
        });
    }

    onScroll(navLinkId: number) {
        this.setState({activeNavLinkId: navLinkId});
    }
}