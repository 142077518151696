import * as React from "react";
import itunesPng from "./../../static/img/store/itunes.png";

export interface BuyOnITunesButtonProps {
    link: string,
    width?: number,
    height?: number
}

export class BuyOnITunesButton extends React.Component<BuyOnITunesButtonProps, null> {
    render() {
        return (
            <a href={this.props.link} target="_blank">
                <img src={itunesPng}
                     width={this.props.width}
                     height={this.props.height}/>
            </a>
        );
    }
}