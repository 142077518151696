import * as React from "react";
import logo from "../static/img/logo_white_500x500.png";

export interface LogoProps {
    width: number,
    height: number
}

export class Logo extends React.Component<LogoProps, null> {
    render() {
        return <img id="logo"
                    src={logo}
                    width={this.props.width}
                    height={this.props.height}/>;
    }
}